<template>
  <div id="app">
    <Header></Header>
  <!-- 路由出口 -->
  <!-- 路由匹配到的组件将渲染在这里 -->
  <transition name="fade">
      <router-view></router-view>
    </transition>
  <Footer></Footer>

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'App',
  components: {
    Header,Footer,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 20px;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter{
  opacity: 0;
}
</style>

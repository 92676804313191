import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)



let routes = new Router({
  routes: [{
      path: '/',
      name: 'home',
      component: () => import('../views/Home.vue')
    },
    {
      path: '/service',
      name: 'service',
      component: () => import('../views/Service.vue'),
    },
   
    {
      path: '/product',
      name: 'product',
      component: () => import('../views/Product.vue'),
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/About.vue')
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: () => import('../views/Contactus.vue')
    },
   
  ]
})



export default routes
<template>
  <div>
    <el-container>
      <div class="footer">
        <div class="footer-content">
          <ul class="content-nav" style="margin: 0 auto">
            <li>
              <p>走进冠智</p>
              <span>发展历程</span>
              <span>企业文化</span>
              <span>资质荣誉</span>
              <span>合作伙伴</span>
            </li>
            <li>
              <p>新闻资讯</p>
              <span>公司新闻</span>
              <span>行业动态</span>
            </li>
            <li>
              <p>产品中心</p>
              <span>介绍视频</span>
              <span>管理模式</span>
              <span>平台目标</span>
              <span>功能模块</span>
            </li>
            <li>
              <p>联系我们</p>
              <span>邮箱：15206188@qq.com</span>
              <span>电话：021-55802368</span>
              <span
                >地址：深圳市福田区福田街道岗厦社区彩田南路3001号彩福大厦D3175</span
              >
            </li>
          </ul>
          <!-- <img src="../assets/img/ercode.png" alt /> -->
        </div>
      </div>
    </el-container>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      router: true,
      defaultActive: "/",
      isShow: false,
    };
  },
  methods: {
    handleSelect(key) {
      this.isShow = this.defaultActive != key;
      window.console.log(this.isShow);
    },
  },
};
</script>
  
  <style lang="scss">
* {
  padding: 0;
  margin: 0;
}

.footer {
  width: 100%;
  height: 230px;
  overflow: hidden;
  background-color: gray;
  &-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    .content-nav {
      display: flex;
      justify-content: space-around;
      li {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        //justify-content: center;
        align-items: flex-start;
        p {
          font-size: 20px;
          color: #d4edff;
          padding: 10px 0;
        }
        span {
          color: #f7f7f7;
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }
    img {
      width: 170px;
      height: 170px;
      padding: 10px;
    }
  }
  .copyright {
    height: 30px;
    background: #125688;
    span {
      color: #fff;
      line-height: 30px;
    }
  }
}
</style>
  